import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

import * as Constants from "../../lib/constants";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <p>
          Please send an email to {Constants.CONTACT_EMAIL} using the same email
          account you are using in the app. Keep in mind that this will not
          cancel your subscription and all of your progress will be lost.
        </p>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
